import React, { useRef, useState } from 'react'
import { FaEnvelope, FaFacebookMessenger, FaWhatsapp } from 'react-icons/fa'
import emailjs from'emailjs-com'
import './contact.scss'

export const Contact = () => {
  const [alerta, setAlerta] = useState({msg:'',error:false})
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_a334cth', 'template_8jti87j', form.current, 'ipIT-SnnKhuAD-5gV')
      .then((result) => {
          console.log(result.text);
          setAlerta({msg:'Email enviado correctamente', error:false})
      }, (error) => {
          console.log(error.text);
          setAlerta({msg:error.text, error:true})
      });

      e.target.reset()
      
  };
  
  
  
  const {msg}=alerta

  if(msg){
    setTimeout(() => {
      setAlerta({})
    }, 4000);
  }
  return (
    <section id='contact'>
    <h5>Si quieres saber más o <br/> solicitar un presupuesto</h5>
    <h2>Contáctame</h2>

    <div className="container contact__container">
      <div className="contact__options">
        <article className="contact__option">
          <FaEnvelope className='contact__option-icon' />
                 
          <a className='btn-primary' href="mailto:sebastian@digitalleads.es" target='_blank' rel="noreferrer">Enviar Email</a>
        </article>

        <article className="contact__option">
          <FaFacebookMessenger className='contact__option-icon'/>
                  
          <a className='btn-primary' href="https://m.me/100088390154634" target='_blank' rel="noreferrer">Enviar Mensaje</a>
        </article>

        <article className="contact__option">
          <FaWhatsapp className='contact__option-icon'/>
               
          <a className='btn-primary' href="https://wa.me/34722461272/" target='_blank' rel="noreferrer">Enviar WhatsApp</a>
        </article>
      </div>
    
      <form ref={form} onSubmit={sendEmail}>
    
      <h3 className='text-light'>Formulario de Contacto</h3>
        <input type="text" name='nombre' placeholder='Tu Nombre' required/>
        <input type="email" name='email' placeholder='Tu Email' required/>
        <textarea name="mensaje" rows="7" placeholder='Tu Mensaje' required></textarea>
        <input className='btn' type="submit" value='Enviar'/>
        {msg ? <span className={alerta.error ? 'alerta error' : 'alerta succes'}>{alerta.msg}</span> : ''}
      </form>
    </div>
  </section>
  )
}
