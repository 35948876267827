import React from 'react'
import { FaAngleDoubleUp } from 'react-icons/fa'
import { Neon } from '../headerNeon/Neon'
import './footer.scss'

export const Footer = () => {
  return (
    <footer>
      <div className="up__button">
        <a href="#home"><FaAngleDoubleUp /></a>
      </div>
      <div className="footer__container">

        <div className='footer__logo'>
          <h1>{'/ Desarrollo y Diseño Web /'}</h1>
          <Neon className={'footer__neon'}/>
          <h5 className='text-light'>{'{ Fullstack Developer }'}</h5>
        </div>

        <div className='footer__links'>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#about">Sobre mí</a></li>
            <li><a href="#skills">Skills</a></li>
            <li><a href="#services">Servicios</a></li>
            <li><a href="#portfolio">Proyectos</a></li>
            <li><a href="#testimonials">Testimonios</a></li>
            <li><a href="#contact">Contacto</a></li>
          </ul>
        </div>
      </div>
    </footer>
  )
}
