import React from 'react'
import './neon.scss'

export const Neon = () => {
    return (
        <div class="container__neon">
            <div class="neon">Sebastián </div>
            <div class="flux">Portunato</div>
        </div>
    )
}
