import React, { useState } from 'react'
import './nav.scss'
import { FaHome, FaWhatsapp } from "react-icons/fa";
import { MdOutlineHomeRepairService } from "react-icons/md";
import { BsShieldFillCheck } from "react-icons/bs";

export const Nav = () => {
  const [clicked, setClicked] = useState(false);
  const [activeNav, setActiveNav] = useState('#home')



  return (
    <>
      <div className="navigation">
        <div onClick={() => setClicked(!clicked)} className={clicked ? 'menuToggle active' : 'menuToggle'}></div>
        <div className="menu">
          <ul>
            <li><span><a href="#home"
              onClick={() => setActiveNav('#home')}
              className={activeNav === '#home' ? 'active' : ''}
            ><FaHome /></a></span></li>

            <li><span><a href="#skills"
              onClick={() => setActiveNav('#skills')}
              className={activeNav === '#skills' ? 'active' : ''}
            ><BsShieldFillCheck /></a></span></li>

            <li><span><a href="#services"
              onClick={() => setActiveNav('#services')}
              className={activeNav === '#services' ? 'active' : ''}
            ><MdOutlineHomeRepairService /></a></span></li>

            <li><span><a href="https://wa.me/34722461272/" rel='noreferrer' target='_blank'
              onClick={() => setActiveNav('#contact')}
              className={activeNav === '#contact' ? 'active' : ''}
            ><FaWhatsapp /></a></span></li >
          </ul >
        </div >
      </div >
    </>
  )
}
