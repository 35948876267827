import React from 'react'
import { FaFacebook, FaLinkedin, FaWhatsapp } from 'react-icons/fa'

export const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.facebook.com/profile.php?id=100088390154634" target={'_blank'}  rel="noreferrer" ><FaFacebook/></a>
        <a href="https://www.linkedin.com/in/sebas-portunato-gigena-515070260/" target={'_blank'}  rel="noreferrer" ><FaLinkedin/></a>
        <a href="https://wa.me/34722461272/" target='_blank' rel="noreferrer"><FaWhatsapp/></a>
    </div>
  )
}
