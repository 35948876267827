import React from 'react'
import './modal.scss'

export const Modal = ({setShowModal, modalContent}) => {
    return (
        <div className="modal__container">
            <div className="modal__wrapper">
                <small onClick={()=>setShowModal(false)} >X</small>
                <h2>{modalContent.titulo}</h2>
                <p>{modalContent.parrafo}</p>
                <span onClick={()=>setShowModal(false)}  className='btn'>cerrar</span>
            </div>
        </div>
    )
}
