import React from 'react'

export const CTA = () => {
  return (
    <div className='cta'>
        <a className='btn' href='#contact'>Solicitar Presupuesto</a>
        <a className='btn-primary' href='#portfolio'>Proyectos</a>
        <a className='btn' href='#about'>Conóceme</a>
    </div>
  )
}
