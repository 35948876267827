import React from 'react'
import { CTA } from './CTA'
import './header.scss'
import me from '../../assets/img/sebas2.png'
import { HeaderSocials } from './HeaderSocials'
import { Neon } from '../headerNeon/Neon'

export const Header = () => {
  return (
    <header id='home'>
      <div className="container header__container">
        <h1>{'/ Desarrollo y Diseño Web /'}</h1>
        <Neon/>        
        <h5 className='text-light'>{'{ Fullstack Developer }'}</h5>
        <CTA />
        
        <HeaderSocials/>
        <div className="me">
          <img src={me} alt="" />
        </div>
       
     
        <a href="#contact" className='scroll__down'>Scroll Down</a>
      </div>
     
    </header>
  )
}
