import React, { useState } from 'react'
import { FaCss3Alt, FaHtml5, FaNodeJs, FaPhp, FaReact, FaRegStar, FaSass, FaStar, FaStarHalfAlt, FaWordpress } from 'react-icons/fa'
import { IoLogoJavascript } from 'react-icons/io'
import { SiExpress, SiMongodb, SiMysql } from 'react-icons/si'
import { Modal } from '../modal/Modal'

import './skills.scss'

export const Skilss = () => {
  const [showHelper, setShowHelper] = useState(false);
  const [showHelperBack, setShowHelperBack] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    titulo:'',
    parrafo:''
  });

  const handleModal = (type)=>{
    setShowModal(true)

    if(type === 'frontend'){
      setModalContent({
        titulo:'¿Qué es el desarrollo Frontend?',
        parrafo:'El frontend es la parte del desarrollo web que se dedica a la parte frontal de un sitio web, en pocas palabras del diseño de un sitio web, desde la estructura del sitio hasta los estilos como colores, fondos, tamaños hasta llegar a las animaciones y efectos.'
      })
    }else if(type === 'backend'){
      setModalContent({
        titulo:'¿Qué es el desarrollo Backend?',
        parrafo:'El Backend, también conocido como CMS o Backoffice, es la parte de la app o la web que el usuario final no puede ver. Su función es acceder a la información que se solicita, a través de la app, para luego combinarla y devolverla al usuario final'
      })
    }
  }

  return (
    <section id='skills'>
      {showModal && <Modal setShowModal={setShowModal} modalContent={modalContent}/>}

      <h5>Tecnologías que manejo y con las que <br/> juntos conseguiremos tus objetivos</h5>
      <h2>Skills</h2>

      <div className="container experience__container">
        <div className="experience__frontend">

        <div onMouseEnter={()=>setShowHelper(true)} 
        onMouseLeave={()=>setShowHelper(false)} className='skill__title'><h3>Desarrollo Frontend</h3>{showHelper &&<span className='popUp'><p onClick={()=>handleModal('frontend')}>¿Qué es Frontend?</p></span>}</div>

          <div className="experience__content">
            <article className="experience__details">
              <FaHtml5 className='experience__details-icon' />
              <div>
                <h4>HTML</h4>
                <small className='text-light'><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /><FaRegStar /></small>
              </div>
            </article>

            <article className="experience__details">
              <FaCss3Alt className='experience__details-icon' />
              <div>
                <h4>CSS</h4>
                <small className='text-light'><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /><FaRegStar /></small>
              </div>
            </article>

            <article className="experience__details">
              <FaSass className='experience__details-icon' />
              <div>
                <h4>SASS</h4>
                <small className='text-light'><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /><FaRegStar /></small>
              </div>
            </article>

            <article className="experience__details">
              <IoLogoJavascript className='experience__details-icon' />
              <div>
                <h4>JavaScript</h4>
                <small className='text-light'><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /><FaRegStar /></small>
              </div>
            </article>

            <article className="experience__details">
              <FaReact className='experience__details-icon' />
              <div>
                <h4>React</h4>
                <small className='text-light'><FaStar /><FaStar /><FaStar /><FaRegStar /><FaRegStar /></small>
              </div>
            </article>

            <article className="experience__details">
              <FaWordpress className='experience__details-icon' />
              <div>
                <h4>Wordpress</h4>
                <small className='text-light'><FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /></small>
              </div>
            </article>
          </div>
        </div>

        <div className="experience__backend">
        <div onMouseEnter={()=>setShowHelperBack(true)} 
        onMouseLeave={()=>setShowHelperBack(false)} className='skill__title'><h3>Desarrollo Backend</h3>{showHelperBack &&<span className='popUp'><p onClick={()=>handleModal('backend')}>¿Qué es Backend?</p></span>}</div>
          <div className="experience__content">
            <article className="experience__details">
              <FaNodeJs className='experience__details-icon' />
              <div>
                <h4>NodeJS</h4>
                <small className='text-light'><FaStar /><FaStar /><FaStar /><FaRegStar /><FaRegStar /></small>
              </div>
            </article>

            <article className="experience__details">
              <SiMongodb className='experience__details-icon' />
              <div>
                <h4>MongoDB</h4>
                <small className='text-light'><FaStar /><FaStar /><FaStar /><FaRegStar /><FaRegStar /></small>
              </div>
            </article>

            <article className="experience__details">
              <FaPhp className='experience__details-icon' />
              <div>
                <h4>PHP</h4>
                <small className='text-light'><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /><FaRegStar /></small>
              </div>
            </article>

            <article className="experience__details">
              <SiMysql className='experience__details-icon' />
              <div>
                <h4>MySQL</h4>
                <small className='text-light'><FaStar /><FaStar /><FaStar /><FaStar /><FaRegStar /></small>
              </div>
            </article>

            <article className="experience__details">
              <SiExpress className='experience__details-icon' />
              <div>
                <h4>Express</h4>
                <small className='text-light'><FaStar /><FaStar /><FaStarHalfAlt /><FaRegStar /><FaRegStar /></small>
              </div>
            </article>

            <article className="experience__details">
              <FaWordpress className='experience__details-icon' />
              <div>
                <h4>Wordpress</h4>
                <small className='text-light'><FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /></small>
              </div>
            </article>

          </div>
        </div>
      </div>

    </section>
  )
}
