import React from 'react'
import './portfolio.scss'
import img1 from '../../assets/img/larevuelta.jpg'
import img2 from '../../assets/img/frikismakeup.jpg'
import img3 from '../../assets/img/tmo.jpg'
import img4 from '../../assets/img/halcondigital.jpg'
import img5 from '../../assets/img/checkscam.png'
import img6 from '../../assets/img/digitalleads.jpg'

export const Portfolio = () => {

  const data = [
    {
      id: 1,
      img: img1,
      titulo: 'La Revuelta',
      tecno:'Wordpress',
      url: 'www.larevueltazaragoza.es',
      enlace: 'http://larevueltazaragoza.es'
    },
    {
      id: 2,
      img: img2,
      titulo: 'Frikis dek MakeUp',
      tecno:'Wordpress',
      url: 'www.frikisdelmakeup.com',
      enlace: 'http://frikisdelmakeup.com'
    },
    {
      id: 3,
      img: img3,
      titulo: 'Tienda Maquillaje Online',
      tecno:'Aplicacion Web',
      url: 'www.tiendamaquillaje.online',
      enlace: 'http://tiendamaquillaje.online'
    },
    {
      id: 4,
      img: img4,
      titulo: 'Halcón Digital',
      tecno:'Html, Css, Js',
      url: 'www.halcondigital.site',
      enlace: 'http://halcondigital.site'
    },
    {
      id: 5,
      img: img5,
      titulo: 'CheckScam',
      tecno:'Wordpress',
      url: 'www.checkscam.es',
      enlace: 'http://checkscam.es'
    },
    {
      id: 6,
      img: img6,
      titulo: 'Digital Leads',
      tecno:'React',
      url: 'www.digitalleads.es',
      enlace: 'http://digitalleadsm.es'
    }
  ]

  return (
    <section id='portfolio'>
      <h5>Mis Proyectos</h5>
      <h2>Webs</h2>

      <div className="container portfolio__container">

        {data.map(project => (
          <article key={project.id} className="portfolio__item">
            <div className="portfolio__item-image">
              <img src={project.img} alt="" />
            </div>
            <h3>{project.titulo}</h3>
            <p>{project.tecno}</p>
            <p>{project.url}</p>
            <a className='btn' href={project.enlace} target="_blank">Ver Proyecto</a>
          </article>

        ))}

      </div>
    </section>
  )
}
