import React from 'react'
import './banner.scss'

export const Banner = () => {
    return (
        <div className='banner__container' id="text">
            <h1>W<span id="offset">E</span>BS DES<span id="offset">DE</span> 130€</h1>
        </div>
    )
}
