import React from 'react'
import './about.scss'
import ME from '../../assets/img/IMG_20200521_220508.jpg'
import { GiProgression } from "react-icons/gi";
import { FaMedal, FaProjectDiagram } from 'react-icons/fa';
import { AiOutlineLike } from 'react-icons/ai';

export const About = () => {
  return (
    <section id='about'>
      <h5>Conoce más</h5>
      <h2>Sobre mí</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <GiProgression className='about__icon' />
              <h5>Nivel</h5>
              <small>Junior</small>
            </article>

            <article className="about__card">
              <FaMedal className='about__icon' />
              <h5>Experiencia</h5>
              <small>1 año</small>
            </article>

            <article className="about__card">
              <FaProjectDiagram className='about__icon' />
              <h5>Proyectos</h5>
              <small>+ 5</small>
            </article>
          </div>
          <p>¡Hola! binevenid@. Seguramente encontraste esta página web porque buscas tener tu propia <strong>Web Profesional.</strong> Si es así, estás de suerte!!<br /> porque yo te voy ayudar con ello. <strong>{<AiOutlineLike />}</strong></p>
          <p>Mi nombre es Sebas y soy desarrollador web. <strong><br/>¿Y qué hacemos los desarrolladores web?</strong> Pues estamos para que personas como tú, con ideas, proyectos o negocios puedan estar <strong>más presentes que nunca en Internet.</strong></p>
          <p>Tener presencia en internet, hoy día, es vital para <strong>complementar tus servicios y aumentar tus ventas. </strong>
            Una web bien hecha hará que <strong>más potenciales clientes te vean </strong>y posicionarás mejor tu marca, servicio o producto.</p>
            <p><strong>¡Lleva tu idea al siguiente nivel!</strong></p>

          <a href="#contact" className='btn-primary'>Pideme información </a>
        </div>
      </div>
    </section>
  )
}
