import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { Banner } from '../banner/Banner'
import './services.scss'

export const Services = () => {
  return (
    <section id='services'>
      <h5>Estrategia <strong> | </strong> Desarrollo <strong> | </strong> Analítica</h5>
      <h2>Mis servicios</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Diseño UI/UX</h3>
          </div>
          <ul className="service__list">
            <li>
              <FaCheckCircle className='service__list-icon' />
              <p>Exelente experiencia de usuario en tu web.</p>
            </li>

            <li>
              <FaCheckCircle className='service__list-icon' />
              <p>Interfaces intiutivas e innovadoras.</p>
            </li>

            <li>
              <FaCheckCircle className='service__list-icon' />
              <p>Webs adaptables a todos los dispositivos.</p>
            </li>

            <li>
              <FaCheckCircle className='service__list-icon' />
              <p>Más retención y más clientes en tu web.</p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Desarrollo y Diseño Web</h3>
          </div>
          <ul className="service__list">
            <li>
              <FaCheckCircle className='service__list-icon' />
              <p>Webs realizadas con código a tu medida.</p>
            </li>

            <li>
              <FaCheckCircle className='service__list-icon' />
              <p>Las webs más rápidas en cargar.</p>
            </li>

            <li>
              <FaCheckCircle className='service__list-icon' />
              <p>100% personalizables y adaptables a tu marca.</p>
            </li>

            <li>
              <FaCheckCircle className='service__list-icon' />
              <p>Ideal para negocios o proyectos online y offline.</p>
            </li>
            <li>
              <FaCheckCircle className='service__list-icon' />
              <p>Webs super económicas con Wordpress.</p>
            </li>
            <li>
              <FaCheckCircle className='service__list-icon' />
              <p>Aplicaciones web, ecommerce, pasarelas de pago, etc.</p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>BRANDING - Desarrollo y Diseño de Marca</h3>
          </div>
          <ul className="service__list">
            <li>
              <FaCheckCircle className='service__list-icon' />
              <p>Posiciona tu marca, por encima de tu competencia.</p>
            </li>

            <li>
              <FaCheckCircle className='service__list-icon' />
              <p>Convierte a tus clientes en Fanlovers.</p>
            </li>

            <li>
              <FaCheckCircle className='service__list-icon' />
              <p>Estrategias de Marketing y Brandig.</p>
            </li>

            <li>
              <FaCheckCircle className='service__list-icon' />
              <p>Distínguete de tu competencia.</p>
            </li>
          </ul>
        </article>

       
      </div>
      <Banner /> 
    </section>
  )
}
